<template>
  <v-row justify="center">
    <v-col cols="12" md="8">
      <v-card>
        <!-- Información básica de la empresa -->
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <span class="text-h5 white--text">{{ empresa.razonSocial }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>{{ empresa.nombreFantasia }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <!-- Detalles de la empresa -->
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-file-document</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title><strong>CUIT:</strong> {{ empresa.cuit }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <strong>Fecha de Inicio:</strong>
                {{ formatFecha(empresa.fecha_inicio_actividades) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-email</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title><strong>Email:</strong> {{ empresa.email }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-map-marker</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ empresa.direccion }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-map-marker</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ empresa.direccion }},
                {{ empresa.localidad ? empresa.localidad.nombre : "N/A" }} -
                {{ empresa.localidad && empresa.localidad.provincia ? empresa.localidad.provincia.nombre : "N/A" }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <!-- Botón para invocar setEmpresa -->
        <v-btn @click="setEmpresa(empresa)" color="primary" class="mt-4">
          Establecer Empresa en Vuex
        </v-btn>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import moment from "moment";

export default {
  name: "EmpresaDetailDatos",
  components: {
    LayoutBasic,
  },
  props: {
    empresa: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      empresaData: {}, // Aquí guardamos los datos de la empresa internamente
    };
  },
  methods: {
    setEmpresa(empresa) {
      // Usamos Vuex para almacenar los datos de la empresa en el estado global
      this.$store.commit("empresa/SET_EMPRESA", empresa); // Llamamos a la mutación 'SET_EMPRESA' del módulo 'empresa'
      this.$store.commit("auth/SET_EMPRESA", empresa); // Llamamos a la mutación 'SET_EMPRESA' del módulo 'empresa'

      
      console.log(empresa);
      
      this.empresaData = { ...empresa }; // Opcional, puedes seguir manteniendo la variable interna
    },
    formatFecha(fecha) {
      return moment(fecha).format("ll"); // Formatea la fecha a un formato largo
    },
  },
};
</script>

<style lang="scss" scoped></style>
